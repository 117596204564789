@font-face {
  font-family: "FontAwesome";
  src: url("https://tueanh.vn/fonts/fontawesome/fontawesome-webfont.eot?v=4.7.0");
  src: url("https://tueanh.vn/fonts/fontawesome/fontawesome-webfont.eot?#iefix&v=4.7.0")
      format("embedded-opentype"),
    url("https://tueanh.vn/fonts/fontawesome/fontawesome-webfont.woff2?v=4.7.0")
      format("woff2"),
    url("https://tueanh.vn/fonts/fontawesome/fontawesome-webfont.woff?v=4.7.0")
      format("woff"),
    url("https://tueanh.vn/fonts/fontawesome/fontawesome-webfont.ttf?v=4.7.0")
      format("truetype"),
    url("https://tueanh.vn/fonts/fontawesome/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
