@media screen and (max-width: 767px) {
  .nav-menu {
    width: 300px;
    .main_menu_mega {
      display: none;
    }
    li {
      border-top: 1px solid rgba(120, 120, 120, 0.15);
      & > a {
        padding: 0px 20px 0px 10px;
        height: 46px;
        font-size: 16px;
        text-decoration: none;
        line-height: 46px;
        color: #333;
        -webkit-transition: color 0.2s ease;
        -moz-transition: color 0.2s ease;
        -ms-transition: color 0.2s ease;
        -o-transition: color 0.2s ease;
        transition: color 0.2s ease;
      }
    }
  }
  .nav-menu-header {
    display: block!important;
    background-color: #106d5d;
    padding: 0px 20px 0px 10px;
    color: #FFFFFF;
  }
}
