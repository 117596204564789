.glassez-detail-page {
  margin-top: 50px;
  h2 {
    margin: 0;
    margin-bottom: 15px;
  }
  .glassez-container {
    display: flex;
    gap: 20px;
    > div {
      width: 50%;
    }
    .glassez-images {
      .head {
        display: flex;
        margin-bottom: 20px;
        .best-seller {
          padding: 4px 8px;
          border-radius: 4px;
          background-image: -o-radial-gradient(
            -11% 19%,
            circle,
            #a7f4e4,
            #f2f6e8 46%,
            #a7f4e4 97%
          );
          background-image: radial-gradient(
            circle at -11% 19%,
            #a7f4e4,
            #f2f6e8 46%,
            #a7f4e4 97%
          );
          color: #222;
          text-transform: uppercase;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.5px;
          font-weight: 700;
          cursor: default;
        }
      }
      img {
        width: 100%;
        cursor: pointer;
      }
      .image-item {
        background-color: #f1f2f4;
        padding: 20px;
      }
    }
    .sub-imgs {
      margin-top: 10px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
      align-items: center;
      .image-item {
        display: flex;
        align-items: center;
        height: 140px;
      }
    }
    .glassez-info {
      display: flex;
      flex-flow: column;
      margin-left: 20px;
      .head-border {
        display: flex;
        flex-flow: column;
        align-items: start;
      }
      .price {
        margin-bottom: 20px;
        font-size: 20px;
      }
      .description {
        text-align: left;
        margin: 20px;
        border-bottom: solid 1px #ccc;
        padding-bottom: 35px;
      }
      .foot-area {
        margin-top: auto;
        margin-bottom: 20px;
        .btn-primary {
          display: inline-block;
          border-radius: 20px;
          background-color: #106d5d;
          color: #ffffff;
          font-size: 14px;
          text-transform: uppercase;
          border: none;
          width: 150px;
          height: 40px;
          line-height: 1.75;
          padding: 8px 12px;
          margin-right: 12px;
        }
      }
    }
  }
  .MuiButton-root svg {
    padding-right: 5px;
  }
}

@media only screen and (max-width: 495px) {
  .glassez-detail-page {
    margin-top: 25px;
    .glassez-container {
      flex-flow: column;
      > div {
        width: 100%;
      }
      .mobile .code {
        font-weight: bold;
      }
    }
    .detail img {
      width: 100% !important;
      height: auto !important;
    }
  }
}

.desc-tabs {
  .MuiTabs-flexContainer {
    display: flex;
    justify-content: center;
  }
  .MuiTabs-indicator {
    display: none;
  }
  .MuiButtonBase-root {
    color: #222;
    font-size: 14px;
    text-transform: unset;
    min-height: unset;
    border: 1px solid transparent;
    &.Mui-selected {
      border-color: #222;
      border-radius: 30px;
      color: #222;
    }
  }
}

.carousel-root {
  display: flex;
  > .carousel {
    order: 0;
    flex: 1;
  }
  > .carousel-slider {
    order: 1;
    flex: 10;
  }
  .thumbs-wrapper {
    width: 100px;
    .thumbs {
      display: flex;
      flex-direction: column;
      transform: none!important;
      .thumb {
        margin-bottom: 16px;
      }
    }
  }
}